/* Styles for IE 11*/
* {
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: #F4FAFC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #EFF6F7;
    -webkit-box-shadow: 0px 0px 60px rgba(230, 222, 222, 0.38);
            box-shadow: 0px 0px 60px rgba(230, 222, 222, 0.38);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .header-left {
    width: 180px;
  }
  
  .header-left-logo-style {
    width: 100px;
    padding-left: 20px;
    padding-top: 10px;
  }
  
  .header-left-name {
    width: 90%;
    border: solid thin;
  }
  
  .header-left-desc {
    padding-left: 20px;
  }

  .header-left-desc-poweredby {
    font-size: 14px;
  }
  
  .header-left-desc-poweredby-text {
    color: #717171;
  }
  
  .header-left-desc-poweredby-text-veeone {
    color: #00B0CB;
    font-weight: 500;
  }
  
  .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: calc(100% - 180px);
    justify-content: space-between;
    background-color: #00B0CB;
    border-radius: 0 0 0 6.125rem;
  }
  
  .header-right-name {
    font-size: 18px;
    color: white;
    padding-left: 40px;
  }
  
  .header-right-help {
    color: white;
    padding-right: 3vw;
  }
  
  /* Main content styles */
  .main-content {
    background-color: white;
    height: 87vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }
  
  .main-content-first {
    height: 90px;
  }
  
  .main-content-second {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .main-content-second-left {
    width: 33vw;
  }
  
  .main-content-second-middle {
    min-width: 450px;
    height: 310px;
    background-color: #f7fbfb;
    border-radius: 20px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 0px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 0px;
  }

  .main-content-second-middle-error {
    min-width: 450px;
    background-color: #f7fbfb;
    border-radius: 20px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 0px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 0px;
  }

  .main-content-spinner {
    height: 400px; 
    line-height: 400px; 
    text-align: center; 
    vertical-align: middle;
  }

  .content-error-icon {
    border: 2px solid red;
    border-radius: 50px;
    color: red;
    transform: scale(1.5)
  }
  
  .main-content-second .content {
    height: 100%;
    /* content Facility details styles */
    /* content facility details end */
    /* content status details styles */
  }
  
  .main-content-second .content-header {
    height: 40px;
  }
  
  .main-content-second .content-facility {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
  }

  .main-content-second .content-facility-no-device {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .main-content-second .content-facility-left {
    width: 25px;
  }
  
  .main-content-second .content-facility-details {
    width: 450px;
    color: #00B0CB;
    border-left: solid;
    padding-left: 1rem;
    font-size: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
  }

  .main-content-second .content-facility-details-no-device {
    width: 450px;
    color: #00B0CB;
    padding-left: 1rem;
    font-size: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    text-align: center;
  }

  .content-facility-detail-no-device-icon {
    margin-top: 20px;
  }
  
  .main-content-second .content-facility-icon {
    color: #00B0CB;
    padding-right: 35px;
    padding-top: 15px;
  }
  
  .main-content-second .content-facility-icon-style {
    font-size: 2rem;
  }
  
  .main-content-second .content .content-spacer-2 {
    height: 40px;
  }
  
  .main-content-second .content-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
  }
  
  .main-content-second .content-status-left {
    width: 25px;
  }
  
  .main-content-second .content-status-details {
    width: 450px;
    color: #00B0CB;
    border-left: solid;
    padding-left: 1rem;
    font-size: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .main-content-second .content-status-details-extra-icon {
    padding-top: 7px;
    padding-left: 10px;
  }

  .main-content-second .status-text-icon {
    display: flex;
  }
  
  .main-content-second .content-status-icon {
    color: #00B0CB;
  }
  
  .main-content-second .content-status-icon-style {
    font-size: 2rem;
  }
  
  .main-content-second .content .content-spacer-3 {
    height: 40px;
  }
  
  .main-content-second .content-connect {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .main-content-second .content-connect-btn {
    background: #00b0cb;
    border: none;
    width: 145px;
    color: white;
    height: 49px;
    line-height: 49px;
    text-align: center;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  
  .main-content-second .content-connect-btn:hover {
    background-color: #048a9f;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .main-content-second .content-connect-btn:not(:hover) {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .main-content-second .content-connect-btn-disabled {
    background-color: #cdd5d6;
    border: none;
    width: 145px;
    color: white;
    height: 49px;
    line-height: 49px;
    text-align: center;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    cursor: default;
  }
  
  .main-content-second .content .content-footer {
    height: 45px;
  }
  
  .main-content-second .content-faciliy-details-extra {
    color: black;
    font-size: 0.9rem;
    padding-top: 0.5rem;
  }

  .main-content-second .content-faciliy-details-extra-no-device {
    color: #848484;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    text-align: center;
  }
  
  .main-content-second .content-status-details-extra {
    color: black;
    font-size: 0.9rem;
    padding-top: 0.5rem;
  }
  
  .main-content-second-right {
    width: 30%;
  }
  
  .main-content-third {
    height: 90px;
  }
/*   
  @media (min-width: 1366px) {
    .header {
      height: 5.5vw;
    }
    .header-left {
      width: 15vw;
    }
    .header-left-logo-style {
      width: 55%;
      padding-left: 2vw;
    }
    .header-left-desc {
      padding-left: 2vw;
      font-size: 1vw;
      padding-top: 0;
    }
    .header-right-name {
      font-size: 1.5vw;
      padding-left: 3vw;
    }
    .header-right-help-style {
      font-size: 3vw;
    }
    .main-content-second-left {
      width: 34vw;
    }
    .main-content-second-middle {
      min-width: 550px;
    }
    .main-content-second-right {
      width: 34vw;
    }
  }
  
  @media (min-width: 1920px) {
    .header {
      height: 5.5vw;
    }
    .header-left {
      width: 15vw;
    }
    .header-left-logo-style {
      width: 55%;
      padding-left: 2vw;
    }
    .header-left-desc {
      padding-left: 2vw;
      font-size: 1vw;
      padding-top: 0;
    }
    .header-right-name {
      font-size: 1.5vw;
      padding-left: 3vw;
    }
    .header-right-help-style {
      font-size: 3vw;
    }
    .main-content-second-left {
      width: 34vw;
    }
    .main-content-second-middle {
      min-width: 550px;
    }
    .main-content-second-right {
      width: 34vw;
    }
  }
  */
  /*# sourceMappingURL=main.css.map */
  